<template>
  <div id="app" :class="$store.state.loading ? 'carregando' : ''">
    <loading></loading>
    <nav class="navbar">
      <div class="icon">
        <router-link to="/" style="display: flex">
          <img src="./assets/LogoGaliBranco.png" alt="Ícone lupa" />
          <span class="hidden-sm-only">Canal de Ética</span> 
        </router-link>
      </div>
      <div class="action">
        <router-link to="/denuncia">Comunicar um fato</router-link>
      </div>
    </nav>
    
    <div>
      <router-view />
    
      <div class="footer">
        <img
          src="./assets/OndaLinhaRoxa.png"
          alt=""
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
  </div>
</template>

 <script>
import Loading from "./components/Loading.vue";
export default {
  name: "AppView",
  components: {
    Loading,
  },
  created() {
    this.$store.dispatch("loading", true);
  },
  mounted() {
    this.$store.dispatch("loading", false);
  },
};
</script>

<style>
body,
html{
  height: 100%;
}
.v-application--wrap {
  padding: 24px;
}
#home.v-application .v-application--wrap {
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: calc(100vh - 34px);
  max-width: 100%;
  position: relative;
  align-items: center;
  padding: 24px;
}

p {
  text-decoration: none !important;
}
</style>

<style lang="scss" scoped>
.footer {
  img {
    opacity: 8%;
    background-blend-mode: lighten;
    transform: scale(1);
  }
  width: 100vw;
  height: 400px;
  position: absolute;
  bottom: 0;
  opacity: 0.5;
  /* background-size: cover; */
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  z-index: -1;
}
#app {
  width: 100vw;
  height: 100%;
      min-height: calc(100% - 64px);
      overflow-x: hidden;
  // height: 100vh;
  position: absolute;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.carregando{
      overflow: hidden;
    height: 100vh;
}
.navbar {
  background-color: #350061;
  padding: 5px 24px 5px 24px;
}
nav {
  display: flex;
  justify-content: space-between;
  padding: 5px;

  a {
    font-weight: 400;
    color: white;
    font-size: 20px;
    text-decoration: none;

    &.router-link-exact-active {
      color: white;
    }
  }
}
.icon {
  img {
    height: 24px;
    margin-right: 15px;
  }
  display: flex;
  justify-content: start;
}
</style>
