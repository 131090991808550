import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/denuncia',
    name: 'FormularioDenuncia',
    component: () => import('../views/FormularioDenuncia.vue')
  },
  {
    path: '/consulta',
    name: 'ConsultaDenuncia',
    component: () => import('../views/ConsultaView.vue')
  },
  {
    path: '/denuncia/:id',
    name: 'Denuncia',
    component: () => import('../views/DenunciaView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    nome: 'Não Encontrado',
    component: () => import("../views/Errors/404.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
