<template>
  <v-app id="home">
    <div class="conteudo">
      O Canal de Ética da GALI é o meio pelo qual qualquer pessoa poderá
      denunciar comportamentos antiéticos ou condutas em desconformidade com
      qualquer política ou procedimento GALI ou à qualquer legislação vigente,
      incluindo atos contrários às Leis nº 12.846/2013 e 14.457/2022. Este Canal
      de Ética se destina ao público geral, interno ou externo. Todas as
      informações que você enviar serão confidenciais e protegidas, garantindo o
      sigilo das informações, a proteção dos dados do denunciante e a não
      retaliação ao denunciante de boa-fé, bem como serão apuradas de maneira
      imparcial e responsável.
    </div>

    <div class="icons">
      <div class="link-wrap">
        <a class="link" href="/Politica_canal_etica.pdf" download>
          <div class="icon">
            <img src="../assets/baixar.png" alt="Ícone livro de ética" />
          </div>
          <p>Política Canal de Ética</p>
        </a>
      </div>

      <div class="link-wrap">
        <a class="link" href="/Codigo_Conduta_Etica.pdf" download>
          <div class="icon">
            <img src="../assets/baixar.png" alt="Ícone livro de ética" />
          </div>
          <p>Código de Conduta e Ética</p>
        </a>
      </div>

      <router-link
        to="/denuncia"
        class="link-wrap"
        style="text-decoration: none; color: inherit"
      >
        <div class="link">
          <div class="icon">
            <img src="../assets/comunicar.png" alt="Ícone megafone" />
          </div>
          <p>Comunicar um fato</p>
        </div>
      </router-link>

      <router-link
        to="/consulta"
        class="link-wrap"
        style="text-decoration: none; color: inherit"
      >
        <div class="link">
          <div class="icon">
            <img src="../assets/consultar.png" alt="Ícone lupa" />
          </div>
          <p>acompanhar relato</p>
        </div>
      </router-link>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "HomeView",
  methods: {
    // simularClickDownload() {
    //   var link = document.createElement("a");
    //   link.download = "Código de Conduta e Ética.pdf";
    //   link.dispatchEvent(new MouseEvent("click"));
    // },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#home.theme--light.v-application {
  background: none !important ;
}
.link-wrap {
  margin: 24px;
}
.link {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 20px;
  color: #350061;
  border-radius: 24px;
  border: 4px solid #350061;
  padding: 24px;
  text-decoration: none;
  height: 100%;
  p {
    text-decoration: none !important;
    text-transform: uppercase;
  }
}
.link:hover,
.link:active,
.link:target,
.link.link:focus-within,
.link:focus {
  border: 4px solid #ff7300;
  // background-color: #35006194;
  transition: all 0.5s;
  color: #ff7300;
  transform: scale(1.05);
  .icon img {
    filter: hue-rotate(0) brightness(1);
    transition: all 0.1s;
  }
}
.conteudo {
  font-size: 1.1rem;
}
.icons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  // grid-template-rows: auto;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
}
.icon img {
  width: 140px;
  filter: hue-rotate(132deg) brightness(8);
  transition: all 0.1s;
}

.icon {
  margin: 24px 12px 12px 12px;
  p {
    margin: 24px;
    text-transform: uppercase;
  }
}
</style>
