import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
    },
    state: {
        loading: false,
    },
    mutations: {},
    getters: {},
    actions: {
        async loading({ state }, status) {
            if (status) {
                state.loading = true;
            } else {
                var tempo;
                tempo = setInterval(async function () {
                    if (state.loading) {
                        await window.setTimeout(function () {
                            state.loading = false;
                        }, 800);
                        clearInterval(tempo);
                    }
                }, 1000);
            }
        }
    },
});

export default store
